import anime from 'animejs';
import { graphql, Link, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import AnimeLink from '../components/AnimeLink';
import Head from '../components/Head';
import Header from '../components/Header';

const ContactPage: React.VFC<{
  data: any;
  location: Location;
}> = ({ data, location }) => {
  return (
    <>
      <Head title="Contact" description="totorajの連絡先" />
      <main className="body">
        <article>
          <header>
            <h1>Contact</h1>
          </header>

          <section className="article-body">
            <hr className="space" />
            <p>Twitterが一番はやく反応できると思います。</p>
            <p>重要な内容でしたらメールでお願いします。</p>

            <h2>Twitter</h2>
            <p>
              <a href="https://twitter.com/totoraj_game">@totoraj_game</a>
            </p>
            <h2>Mail</h2>
            <p>
              <img
                src="/images/mail.png"
                alt="メールアドレス"
                className="mail"
              />
            </p>
          </section>
        </article>
      </main>
    </>
  );
};

export default ContactPage;
